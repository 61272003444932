import React, { useEffect, useState } from "react";
import Softin from "../images/parceiros/SoftinSistemas.png";
import Enablers from "../images/parceiros/Enablers.png";
import Bivver from "../images/parceiros/Bivver.png";
import AtendePortaria from "../images/parceiros/AtendePortaria.png";
import P2Participacoes from "../images/parceiros/P2Participacoes.png";
import Impera from "../images/parceiros/Impera.png";
import api, { url } from "../api.js";
// import { AuthContext } from "../context/GlobalContextProvider";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Parceiros() {
  var settings = {
    arrows:false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 2,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [ parceiros, setParceiros ] = useState(null)
  
  const getData = async () => {
    var getImages = await api.get('/api/parceiros?populate=*')
    const imageData = getImages.data
    
    if(imageData){
      setParceiros(imageData)
    }
  }
  
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="m-5">
      <h2 className="text-center font-bold base-golden-center mb-2">
        Parceiros
      </h2>

      <Slider {...settings}>
        
        {parceiros?.data.map( item => (
          <img className="p-4 md:p-12 xl:p-20" src={"http://localhost:1337" + item?.attributes?.Logo?.data?.attributes?.url} />
        ))}
      </Slider>      
    </div>
  );
}

{/* <img src={Softin} className="p-4 md:p-12 xl:p-20" />
        <img src={Bivver} className="p-4 md:p-12 xl:p-20" />
        <img src={Enablers} className="p-4 md:p-12 xl:p-20" />
        <img src={AtendePortaria} className="p-4 md:p-12 xl:p-20" />
        <img src={P2Participacoes} className="p-4 md:p-12 xl:p-20" />
        <img src={Impera} className="p-4 md:p-12 xl:p-20" /> */}