import Link from "gatsby-link";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import bannerObras from "../images/BannerExecDeObras.jpg";
import BannerProjetos2 from "../images/BannerProjetos2.jpg";
import bannerSondagens from "../images/BannerSondagens2.jpg";
import ExecucaoObras from "../images/InicialExecDeObras.jpg";
import Projetos from "../images/inicialProjetos2.jpg";
import Sondagens from "../images/InicialSondagens.jpg";
import PreMoldados from "../images/PreMoldadosBanner.png";
import topografia2 from "../images/topografia2.jpeg";
import topografia1 from "../images/topografia2.jpeg";
import percolacao1 from "../images/percolacao1.jpeg";
import cbr2 from "../images/cbr2.jpeg";
import Parceiros from "../components/Parceiros";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Home"
      />

      <Carousel
        autoPlay
        infiniteLoop
        interval={5000}
        transitionTime={500}
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        className="index-carousel"
      >
        <div
          className="md:text-justify mr-auto"
          style={{
            height: "500px",
            backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.1)), url(${bannerObras})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="p-3 md:pl-20 md:pt-32">
            <h1 className="font-bold text-white uppercase">
              Execução de obras
            </h1>
            <h2 className="font-bold text-white">
              Obras de pequeno à grande porte,da fundação ao acabamento.
            </h2>
          </div>
        </div>
        {/* <div
          className="md:text-justify mr-auto"
          style={{
            height: "500px",
            backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.1)), url(${bannerPreMoldados})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="p-3 md:pl-20 md:pt-32">
            <h1 className="font-bold text-white uppercase">Pré-Moldados</h1>
            <h2 className="font-bold text-white">
              Projetamos e fabricamos estruturas pré-moldadas.
            </h2>
          </div>
        </div> */}
        <div
          className="md:text-justify"
          style={{
            height: "500px",
            backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.1)), url(${BannerProjetos2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="p-4 md:pl-20 md:pt-24">
            <h1 className="font-bold text-white uppercase">Projetos</h1>
            <ul className="list-disc">
              <li className="font-bold text-white" style={{fontSize: "28px"}}>Arquitetônico</li>
              <li className="font-bold text-white" style={{fontSize: "28px"}}>Estrutural</li>
              <li className="font-bold text-white" style={{fontSize: "28px"}}>Elétrico</li>
              <li className="font-bold text-white" style={{fontSize: "28px"}}>Hidrossanitário</li>
              <li className="font-bold text-white" style={{fontSize: "28px"}}>Preventivo</li>
            </ul>
          </div>
        </div>
        <div
          className="md:text-justify"
          style={{
            height: "500px",
            backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.1)), url(${bannerSondagens})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="p-4 md:pl-20 md:pt-24">
            <h1 className="font-bold text-white">SONDAGENS</h1>
            <ul className="list-disc">
            <li className="font-bold text-white" style={{fontSize: "32px"}}>Sondagens à Trado (ST)</li>
            <li className="font-bold text-white" style={{fontSize: "32px"}}>
           Sondagens à Percussão com Torquímetro (SPT-T)
            </li>
            <li className="font-bold text-white" style={{fontSize: "32px"}}>Sondagens Rotativa (SR)</li>
            </ul>
          </div>
        </div>
        <div
          className="md:text-justify"
          style={{
            height: "500px",
            backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.1)), url(${topografia2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="p-3 md:pl-20 md:pt-32">
            <h1 className="font-bold text-white uppercase">Topografia</h1>
            <h2 className="font-bold text-white inline-flex">
              Análise precisa de terrenos.
            </h2>
          </div>
        </div>
        <div
          className="md:text-justify"
          style={{
            height: "500px",
            backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.1)), url(${cbr2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="p-3 md:pl-20 md:pt-32">
            <h1 className="font-bold text-white uppercase">Percolação e ensaio CBR</h1>
          </div>
        </div>
      </Carousel>

      <h1 className="font-bold text-center base-golden-center my-16">
        Áreas de atuação
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-6 md:mx-20 mb-12">
        <Link
          to="/execucaodeobras"
          className="img-zoom"
          style={{
            backgroundColor: "gray",
            height: "250px",
            backgroundImage: `url(${ExecucaoObras})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="mt-24 text-center font-bold text-white title-gradient">
            Execução de obras
          </h2>
        </Link>
        <Link
          to="/projetos"
          className="img-zoom"
          style={{
            backgroundColor: "gray",
            height: "250px",
            backgroundImage: `url(${Projetos})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="mt-24 text-center font-bold text-white title-gradient">
            Projetos
          </h2>
        </Link>
        <Link
          to="/sondagens"
          className="img-zoom"
          style={{
            backgroundColor: "gray",
            height: "250px",
            backgroundImage: `url(${Sondagens})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="mt-24 text-center font-bold text-white title-gradient">
            Sondagens
          </h2>
        </Link>
        <Link
          to="/premoldados"
          className="hidden img-zoom"
          style={{
            backgroundColor: "gray",
            height: "250px",
            backgroundImage: `url(${PreMoldados})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="mt-24 text-center font-bold text-white title-gradient">
            Pré-moldados
          </h2>
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mx-6 md:mx-20 mb-12">
        <Link
          to="/topografia"
          className="img-zoom"
          style={{
            backgroundColor: "gray",
            height: "250px",
            backgroundImage: `url(${topografia1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="mt-24 text-center font-bold text-white title-gradient">
            Topografia
          </h2>
        </Link>
        <Link
          to="/percolacao"
          className="img-zoom"
          style={{
            backgroundColor: "gray",
            height: "250px",
            backgroundImage: `url(${percolacao1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="mt-24 text-center font-bold text-white title-gradient">
            Percolação e ensaio CBR
          </h2>
        </Link>
        </div>
      <Parceiros />
    </Layout>
  );
}

export default IndexPage;
